.DaysIntervals {
  flex-grow: 1;
}

.InfoRow {
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.ActionRow {
  display: grid;
  grid-template-columns: 130px 70px 20px;
  align-items: center;
}

.ActivityTypesFilterLabelColor {
  width: 8px;
  height: 8px;
  border-radius: 12px;
}

.IntervalLabel {
  align-items: center;
  color: rgb(72, 64, 77);
  font-size: 14px;
  font-weight: 500;
  font-family: Helvetica Neue,sans-serif;
}

.BreadcrumbsRow {
  margin-bottom: 4px; /* Adjust spacing as needed */
  font-size: 0.9em; /* Slightly smaller font for breadcrumbs */
  color: #888; /* Gray text for breadcrumbs */
}

.DaysIntervalContent {
  flex-grow: 1;
}

.IntervalContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.CommentRow {
  margin-top: 4px;
  margin-right: 10px;
  padding-left: 8px;
}

.CommentText {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MenuIcon {
  margin: 0 5px;
  cursor: pointer;
}

.MenuIcon:hover {
  opacity: 0.5;
  transition: opacity .2s ease;
}

.IntervalRangeLabel {
  font-weight: 600;
  font-size: 14px;
  color: rgb(92 90 93);
}

.DurationLabel {
  font-weight: 800;
  font-size: 18px;
  color: rgb(31 22 36);
  text-align: center;
}
